import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    h4: "h4",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["Data models should be re-used to construct GraphQL queries. The ", _jsx(_components.code, {
        children: "gql"
      }), " function dynamically populates the fields given the model definition."]
    }), "\n", _jsxs(_components.p, {
      children: ["Our BE APIs generally have a ", _jsx(_components.code, {
        children: "whatever-api-url/graphiql"
      }), " route. On the right is a ", _jsx(_components.code, {
        children: "SCHEMA"
      }), " tab with docs on available queries and their shapes/types."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { model as eventsModel } from \"@/data-models/model-user-events.mjs\";\nimport gql from \"@/util/graphql-query.mjs\";\n\nconst getEvents = gql`\n  query GetEvents {\n    events { ${eventsModel} }\n  }\n`;\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "gql--models--magic",
      children: _jsx(_components.a, {
        href: "#gql--models--magic",
        children: "gql + Models = magic"
      })
    }), "\n", _jsx(_components.h4, {
      id: "naming",
      children: _jsx(_components.a, {
        href: "#naming",
        children: "Naming"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["The string directly after ", _jsx(_components.code, {
        children: "query"
      }), "/", _jsx(_components.code, {
        children: "mutation"
      }), " in a query is whatever arbitrary value you want. Use it to name the query and make it easily identifiable."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const getEventsAgain = gql`\n  query ThisTimesItsPersonal {\n    events { ${eventsModel} }\n  }\n`;\n"
      })
    }), "\n", _jsx(_components.h4, {
      id: "string-building",
      children: _jsx(_components.a, {
        href: "#string-building",
        children: "String Building"
      })
    }), "\n", _jsx(_components.p, {
      children: "Any string can be inserted directly"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const isDev = String(IS_DEV);\nconst getEvents = gql`\n  query GetEvents {\n    events(testEvents: ${isDev}) { ${eventsModel} }\n  }\n`;\n"
      })
    }), "\n", _jsx(_components.h4, {
      id: "lvl-1-graphql-fan",
      children: _jsx(_components.a, {
        href: "#lvl-1-graphql-fan",
        children: "Lvl 1 GraphQl fan"
      })
    }), "\n", _jsx(_components.p, {
      children: "The individual queries are not rest endpoints and don't generally have to be treated as separate. Given the schema:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        children: "paymentPlan(...): PaymentPlan\nisEligibleForFreeTrial: Boolean\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["You can hit both with a single query: (This pairs very nicely with using a ", _jsx(_components.a, {
        href: "/docs/guide-data-model",
        children: "afterTransformModel"
      }), " to combine these results)"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const getPricing = gql`\n  query GetProPricing($paymentMethodId: String, $includeUserInfo: Boolean!) {\n    paymentPlan(paymentMethodId: $paymentMethodId) { ${pricingModel} }\n    isEligibleForFreeTrial @include(if: $includeUserInfo)\n  }\n`;\n"
      })
    }), "\n", _jsx(_components.h4, {
      id: "lvl-100-graphql-enjoyer",
      children: _jsx(_components.a, {
        href: "#lvl-100-graphql-enjoyer",
        children: "Lvl 100 GraphQl enjoyer"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["We still have access to all the power of GraphQl when using ", _jsx(_components.code, {
        children: "gql"
      }), " and a model"]
    }), "\n", _jsx(_components.p, {
      children: "For the model:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const eventsModel = {\n  game: String,\n  userPoints: Optional(Number),\n  leaderboard: [Optional(leaderboardShape)],\n  userLeaderboard: [Optional(leaderboardShape)],\n};\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Using builtin ", _jsx(_components.a, {
        href: "https://dgraph.io/docs/graphql/queries/skip-include/",
        children: "GraphQl directives"
      }), " we can optionally include parts of the query"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const getUserEvents = gql`\n  query GetEvents($includeUserInfo: Boolean!) {\n    events {\n      ${gqlParams(eventsModel, {\n        userPoints: \"@include(if: $includeUserInfo)\",\n      })}\n    }\n  }\n`;\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Or access sub-query params:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const getEvents = gql`\n  query GetEventLeaderboard($sortBy: String) {\n    events {\n      ${gqlParams(eventsModel, { leaderboard: \"(sortBy: $sortBy)\" })}\n    }\n  }\n`;\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Or alias a field to request it twice with different params:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const getEvents = gql`\n  query GetEventLeaderboard($sortBy: String, $userId: String) {\n    events {\n      ${gqlParams(eventsModel, {\n        leaderboard: \"(sortBy: $sortBy)\",\n        userLeaderboard: \": leaderboard(userId: $userId)\",\n      })}\n    }\n  }\n`;\n"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
